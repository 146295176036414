import classic from '../assets/images/classic.png';
import sidebyside from '../assets/images/sidebyside.png';
import overlay from '../assets/images/overlay.png';

export const getWidgetPresets = () => [
  {
    id: 'variants-lz15fc10',
    connectedMobilePresetId: 'variants-lz2e4402',
    src: classic,
  },
  {
    id: 'variants-lz2fudbq',
    connectedMobilePresetId: 'variants-lz2kmdco',
    src: overlay,
  },
  {
    id: 'variants-lz2l494r',
    connectedMobilePresetId: 'variants-lz2e4402',
    src: sidebyside,
  },
];
