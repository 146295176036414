import { type EditorSDK } from '@wix/platform-editor-sdk';

export type EditorAppContext = {
  editorSDK: EditorSDK;
  appToken: string;
};

export enum GfppEventId {
  OpenCategoryHeaderElementsPanel = 'openCategoryHeaderElementsPanel',
  OpenManageCategories = 'openManageCategories',
  OpenHeaderLayoutPanel = 'open-blocks-panel: <anfmq>',
}

type Unit = 'px' | 'percentage';

type SliderValue = {
  value: number;
  unit: Unit;
};

export type DesignVariables = {
  contentWidth: SliderValue;
  spacingTitleDescription: SliderValue;
  imageHeight: SliderValue;
  paddingTop: SliderValue;
  paddingBottom: SliderValue;
  imagePosition: number;
  contentPosition: number;
};
