import { type ContextParams, EventType } from '@wix/platform-editor-sdk';
import { openElementsPanel } from '../elements-panel/open-elements-panel';
import { GfppEventId, type EditorAppContext } from '../types';
import headerLayoutJson from '../components/Category header/blocksPanels/Header Layout/.component.json';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { getWidgetPresets } from './presets';

type WidgetGfppClickedParams = {
  context: EditorAppContext;
  options: ContextParams;
};

export const widgetGfppClicked = async ({
  context,
  options,
}: WidgetGfppClickedParams) => {
  const panelsApi = await new PanelsApiFactory()?.createPanelsApi?.({
    editorSDK: context.editorSDK,
    editorType: options.origin.type,
    essentials: options.essentials,
  });

  await context.editorSDK.addEventListener(
    EventType.widgetGfppClicked,
    (event) => {
      const { id, componentRef } = event.detail;

      if (id === GfppEventId.OpenCategoryHeaderElementsPanel) {
        return openElementsPanel({ context, widgetRef: componentRef });
      }

      if (id === GfppEventId.OpenManageCategories) {
        return context.editorSDK.editor.openDashboardPanel(context.appToken, {
          url: 'blog/categories',
          closeOtherPanels: true,
        });
      }

      if (id === GfppEventId.OpenHeaderLayoutPanel) {
        return panelsApi.openBlocksPanel(headerLayoutJson.id, componentRef);
      }
    },
  );
};

export const globalDesignPresetChanged = (context: EditorAppContext) =>
  context.editorSDK.addEventListener(
    EventType.globalDesignPresetChanged,
    async (event): Promise<void> => {
      const { preset, componentRef } = event.detail;
      const [parentRefComponent] =
        await context.editorSDK.components.getAncestors(context.appToken, {
          componentRef,
        });

      if (preset && parentRefComponent) {
        const hasOverrides =
          await context.editorSDK.document.components.refComponents.hasOverrides(
            context.appToken,
            {
              componentRef: parentRefComponent,
            },
          );

        if (hasOverrides) {
          await context.editorSDK.components.refComponents.removeAllOverrides(
            context.appToken,
            {
              componentRef: parentRefComponent,
            },
          );
        }

        await context.editorSDK.application.appStudioWidgets.changePreset(
          context.appToken,
          {
            context: { viewport: 'DESKTOP' },
            componentRef: parentRefComponent,
            stylePresetId: preset,
            layoutPresetId: preset,
          },
        );

        const presets = getWidgetPresets();

        const connectedMobilePresetId = presets.find(
          ({ id }) => id === preset,
        )?.connectedMobilePresetId;

        if (connectedMobilePresetId) {
          await context.editorSDK.application.appStudioWidgets.changePreset(
            context.appToken,
            {
              context: { viewport: 'MOBILE' },
              componentRef: parentRefComponent,
              stylePresetId: connectedMobilePresetId,
              layoutPresetId: connectedMobilePresetId,
            },
          );
        }
      }
    },
  );
